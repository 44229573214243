<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Training Schedules"/>
        <v-spacer></v-spacer>
        <v-btn 
            v-show="canDo && canDo.can.includes('add')"
            class="mx-2" depressed dark color="primary"
            @click="addItem()">
            <v-icon left dark>mdi-plus-circle</v-icon> Add
        </v-btn>
        <v-dialog v-model="dialog" max-width="700px">
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-form v-model="form_valid" ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <!-- <v-text-field v-model="editedItem.training_date" label="Training Date"></v-text-field> -->
                      <v-menu
                        v-model="datePicker"
                        :close-on-content-click="false"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :value="training_date_formatted"
                            label="Training Date"
                            readonly
                            v-on="on"
                            @click:clear="date = null"
                            :disabled="edit_disable"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.training_date"
                          @change="getTrainingDate()"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <!-- <v-text-field v-model="editedItem.training_time_from" label="Time from"></v-text-field> -->
                      <v-menu
                        ref="menuTimeFrom"
                        v-model="timePickerFrom"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="editedItem.timefrom"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedItem.training_time_from"
                            label="Time from:"
                            :disabled="edit_disable"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="timePickerFrom"
                          v-model="editedItem.timefrom"
                          format="24hr"
                          full-width
                          @click:minute="getTimePicker('from')"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <!-- <v-text-field v-model="editedItem.training_time_to" label="Time to"></v-text-field> -->
                      <v-menu
                        ref="menuTimeTo"
                        v-model="timePickerTo"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="editedItem.timeto"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedItem.training_time_to"
                            :disabled="edit_disable"
                            label="Time to:"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="timePickerTo"
                          v-model="editedItem.timeto"
                          format="24hr"
                          full-width
                          @click:minute="getTimePicker('to')"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="9" sm="9" md="9">
                      <v-text-field v-model="editedItem.training_location" 
                      :rules="validate_rules.required" 
                      :disabled="edit_disable"
                      label="Location"></v-text-field>
                    </v-col>
                    <v-col cols="3" sm="3" md="3">
                      <v-text-field v-model="editedItem.training_seat" :rules="validate_rules.required" label="Session Limit (persons)"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6">
                      <v-autocomplete
                      v-model="editedItem.store_uid"
                      :items="rb_store_array"
                      item-text="store_alias_id"
                      item-value="store_uid"
                      label="Store"
                      single-line
                      :rules="[]"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6" sm="6">
                      <v-autocomplete
                      v-model="editedItem.employee_tier_id"
                      :items="employee_tier_array"
                      item-text="name"
                      item-value="id"
                      label="Employee Tier"
                      single-line
                      :rules="[]"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn v-if="canDo && canDo.can.includes('update')" color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
      </v-toolbar> 
    <v-divider></v-divider>
    <div class="content-wrap">
      <v-row>
        <v-col cols="12">
          <v-toolbar class="search-tools pa-0 mb-4" flat>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="5" sm="5">
              <v-text-field
              label="Search : Training Date, Location, Amount ..."
              v-model="options.filter.keyword"
              dense
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
            /> 
            </v-col>
          </v-toolbar>
          <v-data-table 
              :items="filterList" 
              :headers="headers"
              :options.sync="options"
              sort-by="training_date"
              :sort-desc="true"
              :loading="loadingDataTable"
          >
            <template #item.employee_count="{value,item}">
                <span>{{ value }} / {{item.training_seat | comma}}</span>
            </template>
            <template #item.training_date="{value}">
                <span>{{moment(value).format('DD-MM-YYYY')}}</span>
            </template>
            <template #item.training_time="{item}">
                <span>{{ item.training_time_from }} - {{ item.training_time_to }}</span>
            </template>
            <template #item.store_uid="{item}">
                <span>{{ rb_stores[item.store_uid] ? rb_stores[item.store_uid].store_alias_id : "---" }}</span>
            </template>
            <template #item.employee_tier_id="{item}">
                <span>{{ employee_tier[item.employee_tier_id] ? employee_tier[item.employee_tier_id].name : "---" }}</span>
            </template>
            <!-- <template #item.updatedAt="{value}">
                <span>{{moment(value).format('DD-MM-YYYY HH:MM:SS')}}</span>
            </template> -->
            <template #item.actions="{item}">
                <v-btn 
                  v-if="canDo && (canDo.can.includes('detail') || canDo.can.includes('update'))"
                  class="ma-1" outlined color="primary" icon tile small @click="editItem(item)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn 
                  v-if="canDo && canDo.can.includes('delete')"
                  class="ma-1" outlined color="red" icon tile small @click="deleteItem(item)">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import _ from 'lodash';

export default {
  name: 'Trainings',
  components: {
      pageHeading
  },
  data: () => ({
    user_type : "",
    rb_stores : {},
    rb_store_array : [],
    employee_tier : {},
    employee_tier_array : [],
    dialog: false,
    time:null,
    datePicker: false,
    timePickerFrom: false,
    timePickerTo: false,
    edit_disable: false,
    editedIndex: -1,
    
    editedItem: {
      id: -1,
      training_date: new Date().toISOString().substr(0, 10),
      training_time_from: '',
      training_time_to: '',
      training_location: '',
      training_seat: 0,
      timefrom: null
    },
    defaultItem: {
      id: -1,
      training_date: new Date().toISOString().substr(0, 10),
      training_time_from: '',
      training_time_to: '',
      training_location: '',
      training_seat: 0,
    },
    options: {
        filter: {
          keyword: '',
        }
    },
    form_valid : false,
    validate_rules : {
      required: [
        v => !!v || 'This field is required',
      ]
    }
  }),
  methods: {
    ...mapActions(['genCan','getSettings','getTrainings','updateTraining','createTraining','deleteTraining', 'getAllRedbookStore', 'getEmployeeTier']),
    getTrainingDate(){
      this.datePicker = false;
    },
    getTimePicker(type){
      if(type == 'from'){
        this.$refs.menuTimeFrom.save(this.editedItem.timefrom);
        this.editedItem.training_time_from = this.editedItem.timefrom;
      } else {
        this.$refs.menuTimeTo.save(this.editedItem.timeto);
        this.editedItem.training_time_to = this.editedItem.timeto;
      }
    },
    addItem(){
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem);
      this.edit_disable = false;
      this.dialog = true;
    },
    
    editItem (item) {
      this.editedIndex = this.allTrainings.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.timefrom = this.editedItem.training_time_from;
      this.editedItem.timeto = this.editedItem.training_time_to;
      this.edit_disable = item.employee_count > 0 ? true : false;
      this.dialog = true
    },

    deleteItem (item) {
        confirm('Are you sure you want to delete this schedule?') && this.deleteTraining(item);
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation() // prevent validate error show again when click add
      }, 300)
    },
    prepareStoreData : async function () {
        const lodash = require('lodash');
        this.rb_store_array = await this.getAllRedbookStore();
        this.rb_store_array = lodash.orderBy( this.rb_store_array, "store_alias_id");
        this.rb_stores = lodash.keyBy( this.rb_store_array, 'store_uid' );
    },
    prepareEmployeeTierData : async function () {
        const lodash = require('lodash');
        this.employee_tier_array = await this.getEmployeeTier();
        this.employee_tier = lodash.keyBy( this.employee_tier_array, 'id' )
    },
    save () {
      if(this.form_valid){
        if (this.editedIndex > -1) {
          this.updateTraining(this.editedItem);
        } else {
          this.createTraining(this.editedItem);
        }
        this.close()
      }
    },
  },
  computed: {
    ...mapGetters(['canDo','loadingDataTable','allSettings','allTrainings']),
    headers(){
      var columns =  [
        {text: "ID", value: 'id'},
        {text: "Training Date", value: 'training_date'},
        {text: "Time", value: 'training_time', sortable:false},
        {text: "Location", value: 'training_location', width: '250px'},
        {text: "Employee Amount", value: 'employee_count'},
        {text: "Store", value: 'store_uid'},
        {text: "Employee Tier", value: 'employee_tier_id'},
        // {text: "Last Update", value: 'updatedAt'},
        {text: "Action", value: 'actions', sortable:false},
      ];
      if(this.canDo && (this.canDo.can.includes('detail') || this.canDo.can.includes('update') || this.canDo.can.includes('delete'))){
        return columns;
      } else {
        return columns.filter(column => column.value != 'actions');
      }
    },
    formTitle () {
        return this.editedIndex === -1 ? 'New Training' : 'Edit Training'
      },
    training_date_formatted () {
        return moment(this.editedItem.training_date).format('DD-MM-YYYY');
      },
    filterList () { 
      var filter = this.options.filter;
      let local_rb_stores = this.rb_stores;
      let local_employee_tier = this.employee_tier;

      return _.filter(this.allTrainings, function(query){
        var keyword = filter.keyword ? query.training_date.toLowerCase().includes(filter.keyword.toLowerCase()) || query.training_location.toLowerCase().includes(filter.keyword.toLowerCase()) || query.employee_count.toString().toLowerCase().includes(filter.keyword.toLowerCase()) : true;

        if ( local_rb_stores && local_rb_stores[query.store_uid] && local_rb_stores[query.store_uid].store_alias_id )
        {
            keyword = keyword || local_rb_stores[query.store_uid].store_alias_id.toString().toLowerCase().includes(filter.keyword.toLowerCase())
        }

        if ( local_employee_tier[query.employee_tier_id] )
        {
            keyword = keyword || local_employee_tier[query.employee_tier_id].name.toString().toLowerCase().includes(filter.keyword.toLowerCase())
        }

        
        return  keyword;
      })
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    allSettings : {
      handler() {
        if(this.allSettings){
          this.allSettings.forEach(element => {
            if(element.name == 'default_training_seat'){
              this.defaultItem.training_seat = element.value;
              // console.log('get config',this.defaultItem);
            }
          });
        } 
      },
      deep:true
    }
  },
  async beforeMount() {
      await this.prepareStoreData();
      await this.prepareEmployeeTierData();
  },
  created() {
    this.genCan();
    this.getSettings();
    this.getTrainings('all');
    this.user_type = localStorage.getItem('auth_type');
  }
};
</script>